import { daEventCenter } from '@/public/src/services/eventCenter/index'
import { daEventCenter as daEventCenterV2 } from '@/public/src/services/eventCenterV2'
import { v4 as uuidv4 } from 'uuid'
import { SILogger } from 'public/src/pages/common/monitor/index.js'

daEventCenterV2.addSubscriber([
  '1-7-4',
])
daEventCenter.addSubscriber({ modulecode: '1-7-4' })

export const analysis17413 = (extraData) => {
  const data = JSON.parse(JSON.stringify(extraData))

  daEventCenter.triggerNotice({
    daId: '1-7-4-13',
    extraData: data
  })

  daEventCenterV2.triggerNotice({
    id: 'expose_cart_add.comp_add-item',
    data: data,
  })
}

// 关闭弹窗埋点
export const analysis17414 = (extraData) => {
  daEventCenter.triggerNotice({
    daId: '1-7-4-14',
    extraData
  })

  daEventCenterV2.triggerNotice({
    id: 'click_cart_add_close.comp_add-item',
    data: extraData,
  })
}

export const analysis17410 = (extraData) => {
  const _eventId = uuidv4()
  
  daEventCenter.triggerNotice({
    daId: '1-7-4-10',
    extraData: {
      _scene: 3,
      _eventId,
      ...extraData
    }
  })

  daEventCenterV2.triggerNotice({
    id: 'click_back_to_cart.comp_add-item',
    data: {
      _scene: 3,
      _eventId,
      ...extraData,
    },
  })
  
  SILogger.logInfo({
    tag: 'CLICK_BACK_TO_CART',
    message: 'click_back_to_cart_stats',
    params: {
      _scene: 3,
      _eventId,
    }
  }, {
    immediate: true
  })
}

export const analysis17415 = (extraData) => {
  daEventCenter.triggerNotice({
    daId: '1-7-4-15',
    extraData
  })

  // daEventCenterV2.triggerNotice({
  //   id: 'click_cart_add_to_pick.comp_add-item',
  //   data: extraData,
  // })
}
