

import { apolloSuggestedSaleConfig } from './constant.js'

export const isFrPlSuggestedSalePrice = (type) => {
  const FrPlValue = [apolloSuggestedSaleConfig.NORMAL, apolloSuggestedSaleConfig.SPECIAL]
  return FrPlValue.includes(type)
}

export const isDeSuggestedSalePrice = (type) => {
  return type === apolloSuggestedSaleConfig.SPECIALDE
}
export const isNomalSuggestedSalePrice = (type) => {
  return type === apolloSuggestedSaleConfig.NORMAL
}
export const isSpecialSuggestedSalePrice = (type) => {
  return type === apolloSuggestedSaleConfig.SPECIAL
}


/**
 * 法国fr、挪威pl 合规价数据处理
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} goodsInfo 当前处理的价格数据
 * @returns {
 *  needSusaPrice: boolean, // 是否走合规价逻辑
 *  showSusaPrice: boolean, // 走合规价并且没有被倒挂时展示合规价
 *  suggestedSalePrice: {
 *     amount: number, // 合规价金额
 *     amountWithSymbol: string, // 合规价金额带符号
 *     currency: string, // 货币符号
 *     symbol: string, // 货币符号
 *  },
 *  isInversion: boolean, // 是否倒挂
 * }
 * 
 */
export const getFrPlSuggestedSalePrice = (data = {}, priceInfo = {}) => {
  const needSusaPrice = (data?.hasOwnProperty('is_inversion') || data?.hasOwnProperty('isInversion'))

  if (!needSusaPrice) {
    return {
      needSusaPrice: false,
      showSusaPrice: false,
    }
  }

  const suggestedSalePrice = data.suggestedSalePrice || data.suggested_sale_price

  let isInversion = Number(data.is_inversion || data.isInversion) === 1 // true为售价大于合规价
  // 收藏价格取自SKU可能大于合规价
  if (!isInversion && Number(priceInfo?.amount || 0) > Number(suggestedSalePrice?.amount || 0)) {
    isInversion = true
  }

  const suggestedSalePriceAmount = Number(suggestedSalePrice?.amount || 0)

  return {
    needSusaPrice: true, // 是否走合规价逻辑
    showSusaPrice: suggestedSalePriceAmount > 0 && !isInversion, // 走合规价并且没有被倒挂时展示合规价
    suggestedSalePrice,
    isInversion
  }
}

/**
 * 
 * @param {*} goodsInfo 预处理完商品数据
 */
/**
 * 德国de 合规价数据处理
 * @param {*} data 当前展示的售价
 * @param {*} goodsInfo 预处理完商品数据
 * @returns {
 *  needSusaPrice: boolean, // 是否走合规价逻辑
 *  showRetailPrice: boolean, // 是否展示原价(划线价)
 *  showRetailPricePopover: boolean, // 是否展示原价点击气泡
 *  show30DaysLowestPrice: boolean, // 是否展示30天最低价
 * }
 */
export const getDeSuggestedSalePrice = (data, priceInfo, goodsInfo) => {
  const needSusaPrice = (data?.hasOwnProperty('is_inversion') || data?.hasOwnProperty('isInversion'))

  if (!needSusaPrice) {
    return {
      needSusaPrice: false,
      showSusaPrice: false,
    }
  }
  const hideSuggestedSalePrice = goodsInfo.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.hideSuggestedSalePrice

  const { retailPrice } = goodsInfo

  // 30天最低价
  // 展示条件： 大于等于原价
  const suggestedSalePrice = data.suggestedSalePrice || data.suggested_sale_price

  const suggestedSalePriceAmount = Number(suggestedSalePrice?.amount || 0)
  const priceInfoAmount = Number(priceInfo?.amount || 0)
  const retailPriceAmount = Number(retailPrice?.amount || 0)

  // 是否展示30天最低价  30天最低价 > 0 & 30天最低价<售价 && 30天最低价<原价
  let show30DaysLowestPrice = suggestedSalePriceAmount > 0 && 
    suggestedSalePriceAmount !== retailPriceAmount && 
    !(suggestedSalePriceAmount < priceInfoAmount && priceInfoAmount === retailPriceAmount)

  // 是否展示原价(划线价) 30天最低价 > 0 & 售价＜原价 & 30天最低价<原价
  let showRetailPrice = suggestedSalePriceAmount > 0 && priceInfoAmount < retailPriceAmount && suggestedSalePriceAmount <= retailPriceAmount

  // 是否展示原价点击气泡  30天最低价=原价时，隐藏30天最低价 展示原价并且有气泡
  let showRetailPricePopover = showRetailPrice && suggestedSalePriceAmount === retailPriceAmount

  return {
    suggestedSalePrice,
    needSusaPrice: true, // 是否走合规价逻辑
    showSusaPrice: !hideSuggestedSalePrice && showRetailPrice,  // 是否展示原价(划线价)
    showRetailPricePopover: !hideSuggestedSalePrice && showRetailPricePopover, // 是否展示原价点击气泡
    show30DaysLowestPrice: !hideSuggestedSalePrice && show30DaysLowestPrice, // 是否展示30天最低价
    priceInfoAndretailPriceEquivalent: suggestedSalePriceAmount > 0 && retailPriceAmount === suggestedSalePriceAmount &&
      suggestedSalePriceAmount !== priceInfoAmount, // 30天最低价与原价是否等价
  }
}

/**
 * 获取到手价以及标签文字 （仅满足门槛时，Estimated文字标签）
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns 
 */
export const getEstimatedPriceAndLabel = (goodsInfo, language) => {
  
  if (goodsInfo?.pretreatInfo?.suggestedSalePriceConfigInfo?.type) {
    // 合规价隐藏到手价逻辑
    return null
  }

  const { satisfied, estimatedPrice, estimatedPriceType } = goodsInfo.estimatedPriceInfo ?? {}

  const estimatedPriceWithSymbol = estimatedPrice?.amountWithSymbol
  const showBottomTagText = satisfied === 1 && estimatedPriceWithSymbol

  const isSheinClubEstimatedPrice = estimatedPriceType === 2 // 是付费会员到手价

  return {
    estimatedPriceType,
    priceInfo: showBottomTagText && satisfied === 1 ? estimatedPrice : {},
    color: isSheinClubEstimatedPrice ? 'brown' : 'origin',
    labelText: showBottomTagText ? (language?.SHEIN_KEY_PC_27318 || 'Estimated') : '',
  }
}

/**
 * 获取付费会员价以及标签文字
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns 
 */
export const getSheinClubPriceAndLabel = (goodsInfo, language) => {
  const { sheinClubPromotionInfo } = goodsInfo ?? {}
  const price = sheinClubPromotionInfo?.price ?? {}
  const priceInfo = price?.amountWithSymbol ? price : {}

  let susaPriceData = {}

  const SuggestedSalePriceType = goodsInfo?.pretreatInfo?.suggestedSalePriceConfigInfo?.type
  if (isFrPlSuggestedSalePrice(SuggestedSalePriceType)) {
    susaPriceData = getFrPlSuggestedSalePrice(sheinClubPromotionInfo, priceInfo) || {}
  } else if (isDeSuggestedSalePrice(SuggestedSalePriceType)) {
    susaPriceData = getDeSuggestedSalePrice(sheinClubPromotionInfo, priceInfo, goodsInfo)
  }

  return {
    ...susaPriceData,
    priceInfo: price?.amountWithSymbol ? price : {},
    color: susaPriceData.isInversion ? '' : 'brown',
    labelText: price?.amountWithSymbol ? `Est. SHEIN CLUB ${ language?.SHEIN_KEY_PC_27308 || 'price' }` : ''
  }
}

/**
 * 获取新型闪购价以及标签文字
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns 
 */
export const getNewFlashPriceAndLabel = (goodsInfo, language) => {
  const pretreatInfo = goodsInfo.pretreatInfo
  const newFlashPromotion = pretreatInfo?.newFlashPromotion ?? {}
  
  const priceInfo = newFlashPromotion?.amountWithSymbol ? newFlashPromotion : {}

  let labelText = ''
  switch(newFlashPromotion?.flash_type) {
    case 2:
      labelText = `Est. ${language?.SHEIN_KEY_PC_27307 || 'New user price'}`
      break
    case 3:
      labelText = `Est. ${language?.SHEIN_KEY_PC_27306 || 'Exclusive'}`
      break
  }

  let susaPriceData = {}

  const SuggestedSalePriceType = goodsInfo?.pretreatInfo?.suggestedSalePriceConfigInfo?.type
  if (isFrPlSuggestedSalePrice(SuggestedSalePriceType)) {
    susaPriceData = getFrPlSuggestedSalePrice(newFlashPromotion, priceInfo) || {}
  } else if (isDeSuggestedSalePrice(SuggestedSalePriceType)) {
    susaPriceData = getDeSuggestedSalePrice(newFlashPromotion, priceInfo, goodsInfo)
  }

  return {
    ...susaPriceData,
    priceInfo,
    color: susaPriceData.isInversion ? '' : 'origin',
    labelText
  }
}

/**
 * 获取分人群专区闪购价价以及标签文字
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns 
 */
export const getFlashZonePriceAndLabel = (goodsInfo) => {
  const flashZonePromotion = goodsInfo?.flashZonePromotion || {}
  const { price } = flashZonePromotion

  const priceInfo = price?.amountWithSymbol ? price : {}

  let susaPriceData = {}

  const SuggestedSalePriceType = goodsInfo?.pretreatInfo?.suggestedSalePriceConfigInfo?.type
  if (isFrPlSuggestedSalePrice(SuggestedSalePriceType)) {
    susaPriceData = getFrPlSuggestedSalePrice(flashZonePromotion, priceInfo) || {}
  } else if (isDeSuggestedSalePrice(SuggestedSalePriceType)) {
    susaPriceData = getDeSuggestedSalePrice(flashZonePromotion, priceInfo, goodsInfo)
  }

  return {
    ...susaPriceData,
    priceInfo: price?.amountWithSymbol ? price : {},
    color: susaPriceData.isInversion ? '' : 'origin',
    labelText: ''
  }
}

/**
 * 获取售价价以及标签文字
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns 
 */
export const getSalePriceAndLabel = (goodsInfo) => {
  const pretreatInfo = goodsInfo.pretreatInfo

  const price = goodsInfo.salePrice ?? {}

  // 限时直降活动，固定显示红色价格
  const showSellerLimitedLabel = pretreatInfo?.mixPromotionInfo?.some(item => +item?.typeId === 31 && item?.promotion_logo_type === 3)

  const salePriceAmount = Number(price.amount)

  const retailPrice = goodsInfo?.retailPrice ?? {}
  const retailPriceAmount = Number(retailPrice.amount)

  const priceInfo = price?.amountWithSymbol ? price : {}

  let susaPriceData = {}

  const SuggestedSalePriceType = goodsInfo?.pretreatInfo?.suggestedSalePriceConfigInfo?.type
  
  if (isFrPlSuggestedSalePrice(SuggestedSalePriceType)) {
    susaPriceData = getFrPlSuggestedSalePrice(goodsInfo, priceInfo) || {}
  } else if (isDeSuggestedSalePrice(SuggestedSalePriceType)) {
    susaPriceData = getDeSuggestedSalePrice(goodsInfo, priceInfo, goodsInfo)
  } 

  const color = susaPriceData.needSusaPrice ?
    (susaPriceData.isInversion ? '' : 'origin') :
    ((retailPriceAmount > salePriceAmount) || !!showSellerLimitedLabel ? 'origin' : '')

  return {
    ...susaPriceData,
    priceInfo: price?.amountWithSymbol ? price : {},
    color,
    labelText: ''
  }
}

// 默认价格展示优先级
const defaultPricePriority = ['estimatedPrice', 'sheinClubPrice', 'newFlashPrice', 'flashZonePrice', 'salePrice']

const priceDataMap = {
  estimatedPrice: getEstimatedPriceAndLabel,
  sheinClubPrice: getSheinClubPriceAndLabel,
  newFlashPrice: getNewFlashPriceAndLabel,
  flashZonePrice: getFlashZonePriceAndLabel,
  salePrice: getSalePriceAndLabel,
}

/**
 * 获取最终展示的价格对象
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns {String}
 */
export const priceDisplayTheResults = (goodsInfo, language, options = {}) => {
  const isPaid = options?.isPaid ?? false
  
  let priceResults = null
  for (let i = 0; i < defaultPricePriority.length; i++) {
    const key = defaultPricePriority[i]
    const priceData = priceDataMap[key]?.(goodsInfo, language) || {}

    // 是否为付费会员价格  付费会员价 以及 付费会员到手价 estimatedPriceType === 2
    const isSheinClubPriceRes = key === 'sheinClubPrice' || priceData?.estimatedPriceType === 2
    const flag = isSheinClubPriceRes ? isPaid : true

    if (priceData.priceInfo?.amountWithSymbol && flag) {
      priceResults = {
        ...priceData,
        priceType: key,
      }

      break
    }
  }

  // 合规价场景下，隐藏价格说明文字
  const SuggestedSalePriceType = goodsInfo?.pretreatInfo?.suggestedSalePriceConfigInfo?.type
  if (SuggestedSalePriceType && priceResults?.priceInfo) {
    priceResults.labelText = ''
  }

  return priceResults
}


/**
 * 获取最终展示的价格值
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns {String}
 */
export const priceDisplayTheResultsPriceValue = (goodsInfo, language, options = {}) => {
  const results = priceDisplayTheResults(goodsInfo, language, options)

  let resPriceInfo = results?.priceInfo ?? {}
  
  return resPriceInfo.amount ?? 0
}

export const getSuggestedPositionData = (goodsInfo, specialData) => {
  const { retailPrice } = goodsInfo
  
  const suggestedSalePriceConfigInfo = goodsInfo?.pretreatInfo?.suggestedSalePriceConfigInfo ?? {}
  const suggestedSalePriceType = suggestedSalePriceConfigInfo?.type

  const isSpecial = isSpecialSuggestedSalePrice(suggestedSalePriceType)
  const isDe = isDeSuggestedSalePrice(suggestedSalePriceType)
  const isNomal = isNomalSuggestedSalePrice(suggestedSalePriceType)

  const { pricePopoverLang, lowestPriceLabelLang } = suggestedSalePriceConfigInfo

  const priceRight = {
    show: false,
    flipText: false,
    priceData: {},
    labelText: '',
    popoverLang: ''
  }
  const priceBottom = {
    show: false,
    flipText: false,
    priceData: {},
    labelText: ''
  }
  if (!specialData?.needSusaPrice) {
    return {
      priceRight,
      priceBottom
    }
  }

  // 波兰 isSpecial 只显示一个划线价 放价格下边
  if (isSpecial && !specialData.isInversion) {

    priceBottom.show = specialData?.showSusaPrice
    priceBottom.priceData = specialData?.suggestedSalePrice
    priceBottom.labelText = lowestPriceLabelLang
    priceBottom.flipText = false
  } else if (isDe) {
    // 德国 价格右边显示原价  价格下边显示划线价
    priceRight.show = specialData?.showSusaPrice
    priceRight.priceData = retailPrice
    priceRight.labelText = ''
    priceRight.flipText = false
    priceRight.popoverLang = specialData?.showRetailPricePopover ? pricePopoverLang : ''

    priceBottom.show = specialData?.show30DaysLowestPrice
    priceBottom.priceData = specialData?.suggestedSalePrice
    priceBottom.labelText = lowestPriceLabelLang
    priceBottom.flipText = true
  } else if (isNomal && !specialData.isInversion) {
    // 法国 价格右边显示划线价 不显示labelText
    priceRight.show = specialData?.showSusaPrice
    priceRight.priceData = specialData?.suggestedSalePrice
    priceRight.labelText = ''
    priceRight.flipText = false
  }

  return {
    priceRight,
    priceBottom
  }
}

