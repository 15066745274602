import schttp from 'public/src/services/schttp'

export const wishlistListApi = (params) => {
  return schttp({
    url: '/api/user/wishlist/filter/list/get',
    method: 'get',
    params
  })
}

export const wishlistDelApi = (params) => {
  return schttp({
    url: '/api/user/wishlist/del',
    method: 'get',
    params
  })
}

export const wishlistRemoveBatchApi = (params) => {
  return schttp({
    url: '/api/user/wishlist/batch/remove',
    method: 'get',
    params
  })
}

export const addWishGoodsApi = (data) => {
  return schttp({
    url: '/api/user/wishlist/add',
    method: 'post',
    data,
  })
}

export const getShareGroupTokenApi = (params, isBff = false) => {
  return isBff ? schttp({
    url: '/user/wishlist/getEncryptToken',
    method: 'post',
    data: params,
    useBffApi: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }) : schttp({
    url: '/api/user/wishlist/group/share/token/get',
    method: 'get',
    params,
  })
}

export const getSheinClubApi = () => {
  return schttp({
    url: '/api/user/wishlist/sheinClub/get',
    method: 'get',
    params: {},
  })
}
