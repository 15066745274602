<template>
  <p class="product-item__camecase-wrap">
    <span v-if="!isCamelCasePrice">
      {{ (price && price.amountWithSymbol) || '' }}
    </span>
    <span
      v-else
      :style="{
        display: 'inline-block',
        fontSize: `${minSize}px`
      }"
      class="product-item__camecase-price"
      v-html="viewHtml"
    ></span>
    <slot name="discount-tag"></slot>
  </p>
</template>

<script>
export default {
  name: 'CamelCasePrice',
  props: {
    price: {
      type: Object,
      default: () => {}
    },
    maxSize: {
      type: Number,
      default: 14
    },
    minSize: {
      type: Number,
      default: 12
    },
    isCamelCasePrice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      viewHtml: '',
      radio: 1
    }
  },
  watch: {
    price(val) {
      if (val) {
        this.handlePriceHtml()
      }
    }
  },
  created() {
    this.handlePriceHtml()
  },
  methods: {
    handlePriceHtml() {
      // 货币符号在前
      const startSymbol =
        this.price?.amountWithSymbol?.match(/(^\D+)/)?.[0] || ''
      // 货币符号在后
      const endSymbol = this.price?.amountWithSymbol?.match(/(\D+)$/)?.[0] || ''
      // 去除货币符号的价格
      const priceWithoutSymbol =
        this.price?.amountWithSymbol
          ?.replace(startSymbol, '')
          ?.replace(endSymbol, '') || ''
      // 价格是否有小数
      const isDecimal = !!this.price?.amount?.includes('.')
      // 价格小数部分
      const decimalPrice = isDecimal
        ? priceWithoutSymbol.match(/\D\d+$/)?.[0] || ''
        : ''

      const marginLeftStyle = startSymbol ? 'margin-left:1px;' : ''

      // 价格整数部分
      const integerPrice = priceWithoutSymbol.replace(decimalPrice, '')
      const price = `<span style="font-size:${this.maxSize}px;${marginLeftStyle}" class="product-item__price-integer">${integerPrice}</span><span class="product-item__price-decimal">${decimalPrice}</span>`
      this.viewHtml = startSymbol + price + endSymbol || ''
    }
  }
}
</script>

<style lang="less">
.product-item__camecase-wrap {
  white-space: nowrap;
}
</style>
