import schttp from 'public/src/services/schttp'
import { checkSchttp } from 'public/src/services/schttp/tools'
import newBffUtils from './utils'

const { currency, appLanguage } =
  typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

let versionV1 = ['oneClickPay_v1']
//  查询加购关系
export const fetchPurchaseRelationApi = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    return await schttp({
      method: 'POST',
      url: `/order/ocb/proxy/queryAddPurchaseRelation`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      data: data,
      useBffApi: true
    })
  } else {
    return await schttp({
      method: 'POST',
      url: '/api/ocp/addPurchaseRelation/get',
      data: data
    })
  }
}
// 一键购计价
export const fetchCalculateMallApi = data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    return checkSchttp({
      isAsync: data.isAsync,
      opt: {
        method: 'POST',
        url: `/order/ocb/proxy/calculateMall`,
        headers: {
          AppCurrency: currency,
          AppLanguage: appLanguage
        },
        useBffApi: true,
        ...data.opt
      },
      successCb: data.successCb,
      errorCb: data.errorCb
    })
  } else {
    return checkSchttp({
      isAsync: data.isAsync,
      opt: {
        method: 'POST',
        url: '/api/ocp/calculateMall/get',
        ...data.opt
      },
      successCb: data.successCb,
      errorCb: data.errorCb
    })
  }
}

// 一键购生成订单: 同步&异步
// /order/ocb/proxy/createOrder
export const fetchOrderCreateApi = data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    checkSchttp({
      isAsync: data.isAsync,
      opt: {
        method: 'POST',
        url: `/order/ocb/proxy/createOrder`,
        headers: {
          AppCurrency: currency,
          AppLanguage: appLanguage
        },
        useBffApi: true,
        ...data.opt
      },
      successCb: res => {
        if (data.csrfToken && res?.code == 0 && res.info && res.info.order) {
          res.info.order.pci_csrf_token = data.csrfToken
        }
        data.successCb(res)
      },
      errorCb: data.errorCb
    })
  } else {
    checkSchttp({
      isAsync: data.isAsync,
      opt: {
        method: 'POST',
        url: '/api/ocp/order/create',
        ...data.opt
      },
      successCb: data.successCb,
      errorCb: data.errorCb
    })
  }
}

// 迁移中间接口，java bff 暂无 scrf, 从node层获取
export const fetchCsrfTokenApi = data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    checkSchttp({
      isAsync: false,
      opt: {
        url: `/api/common/csrf/update`
      },
      successCb: res => {
        data.successCb(res?.updateCs || '')
      }
    })
  } else {
    data.successCb('')
  }
}
