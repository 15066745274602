import schttp from 'public/src/services/schttp'
import { abtservice } from 'public/src/services/abt'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'

export const getPageAbt = async () => {
  // 凑单 相关
  const newKeys = ['addnewhotJson', 'Cartshowcoupon', 'orderCouponNewUser', 'CartaddCouponBottomStyle']
  const [abtInfo] = await Promise.all([
    // eslint-disable-next-line @shein-aidc/abt/abt
    abtservice.getUserAbtResult({ newPosKeys: newKeys.join(',') }),
  ])
  return {
    ...abtInfo,
  }
}

/* 获取券数据 */
let couponAbortController = null
export const getCouponInfo = (data) => {
  couponAbortController?.abort()
  couponAbortController = new SchttpAbortCon()
  return schttp({
    url: '/api/coupon/couponCalculateDiff/get',
    method: 'POST',
    data,
    signal: couponAbortController?.signal
  })
}

export const getLanguage = async () => {
  return schttp({ 
    url: '/api/common/language/get', 
    params: { page: 'filter' }
  })
}

/* 获取推荐列表数据 */
export const getLists = (params) => {
  const adp = params.contextParams.adp
  const { pageNum } = params
  // 只在第一页进行adp传参
  if (adp && adp.length) {
    if (pageNum > 1) {
      params.contextParams.adp = ''
    } else {
      params.contextParams.adp = adp instanceof Array ? adp.slice(0, 10).join(',') : adp
    }
  }
  return schttp({
    url: `/api/recommend/facadeAtom/get`,
    method: 'POST',
    data: params
  })
}

/* 兜底推荐容错列表接口 */
export const getInsuranceList = (params) => {
  return schttp({
    url: `/api/recommend/faultFacade/query`,
    method: 'POST',
    data: params
  })
} 

export const getInterfaceList = (_params, config) => {
  const { include_tsp_id, exclude_tsp_id, ...other } = _params.contextParams || {}
  const params = {
    ..._params,
    contextParams: {
      ...other,
    }
  }

  // 不能传空字符串
  if (include_tsp_id) params.contextParams.include_tsp_id = include_tsp_id
  if (exclude_tsp_id) params.contextParams.exclude_tsp_id = exclude_tsp_id

  return new Promise((resolve, reject) => {
    getLists(params)
      .then(async (res) => {
        let products = res?.info?.products
        try {
          // 实时获取促销信息
          const { data: promotionInfoFromServer = {} } = await schttp({
            method: 'POST',
            url: '/api/productInfo/postIntegrityPromotionInfo/get',
            data: {
              products,
            },
          })
          products = dealProductsPretreatInfo({
            products,
            itemConfig: config,
            promotionInfoFromServer,
          })
        } catch (error) {
          console.log('promotionInfoFromServer', error)
        }

        resolve({ ...res, products, total: res?.info?.total })
      })
      .catch(() => {
        getInsuranceList({
          ...params,
          cate_id: params.filter_cate_id,
          good_ids: params.goodsIds,
          rec_type: 1, // 推荐类型: (1:商品  4:filter类目)
          pagename: 'page_for_freeshipping',
        })
          .then(async (res) => {
            resolve({ ...res, products: res?.info?.products, total: res?.info?.total, isFaultTolerant: true })
          })
          .catch(reject)
      })
  })
}

