/**
 * 1.   买折
 * 2.   买赠
 * 3.   限时折扣
 * 4.   满赠（全场）
 * 8.   app专享
 * 9.   买N折N
 * 10.  闪购
 * 11.  直播闪购
 * 12.  会员专项折扣
 * 13.  加购价
 * 14.  满减
 * 15.  X元N件 
 * 16.  每买N减N (减元)(非购物车活动)
 * 17.  每买N减N (折扣)(非购物车活动)
 * 18.  买N减N (元)(活动已废弃)
 * 19.  买N减N (折扣)(活动已废弃)
 * 20.  返券
 * 21.  返券 (全场)
 * 22.  加价购 (全场)
 * 24.  打折限购
 * 25.  满折
 * 26.  满折（全场）
 * 28.  满赠（非全场，可有品牌）
 * 29.  付费会员专项
 * 31. 商家直降活动
 * 30. 一口价
 * 31. 商家直降(promotion_logo_type=3限时直降)
 * 32.券后价
 * 33. 品类首津
 */

const PROMOTION_CONFIG = {
  DEBUG: false,
  // 全场活动(不包含全场满减)
  FullCourtPromotionTypeIds: [4, 21, 22, 26],
  // lable 需要异步请求活动详情
  FetchPromotionLableTypeIds: [2, 14, 15, 24, 28],
  // 需要异步请求活动详情的typeId
  NeedFetchPromotionTypeIds: [1, 2, 4, 8, 9, 12, 13, 14, 15, 18, 19, 20, 21, 22, 24, 25, 26, 28, 29, 31],
  // 需要分组的活动
  cartNeedGroupPromotionTypeIds: [1, 2, 9, 11, 13, 15, 18, 19, 20, 24, 25, 28], // 14部分满减特殊处理
  NeedGatherCardPromotionTypeIds: [1, 2, 4, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 24, 25, 28],
  // 需要处理的活动
  NeedDealPromotionTypeIds: [1, 2, 3, 4, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 21, 24, 25, 28, 31],
  // 活动优先级 权重高 => 权重低
  PriorityPromotionTypeIds: [2, 12, 13, 14, 15, 9, 16, 17, 18, 19, 24, 25, 1, 20, 10, 11, 3, 8, 28, 29, 31],
  // 商详所有的活动类型，新增活动在测试的时候会影响到线上
  allPromotionTypeIds: [1, 2, 3, 4, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 28, 31, 32],
  // 需要上报品牌名的活动
  BrandProductPromotionTypeIds: [2, 28],
  // 促销活动标题
  TitlePromotionTypeIds: {
    SHEIN_KEY_PC_16032: [1, 9, 14, 15, 16, 17, 18, 19],  // BuyMoreSaveMore
    SHEIN_KEY_PC_16033: [2, 4, 28],  // GiftsReward
    SHEIN_KEY_PC_15966: [13, 22],    // AddOnItems
    SHEIN_KEY_PC_16035: [20] // ReturnCoupons
  },
  // 促销活动Icon
  IconPromotionTypeIds: {
    'sh_pc_sui_icon_apponly_20px_promo': [8],
    'sh_pc_sui_icon_flashsale_16px_flash': [10, 11],
    'sh_pc_sui_icon_time_16px_promo': [3, 31],
  }
}

export default PROMOTION_CONFIG
